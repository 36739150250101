import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';

import abobrinhaItaliana from '../assets/images/abobrinhaItaliana.png';
import abobrinhaBrasileira from '../assets/images/abobrinhaBrasileira.png'
import berinjela from '../assets/images/berinjela.png';
import beterraba from '../assets/images/beterraba.png';
import cenoura from '../assets/images/cenoura.png';
import chuchu from '../assets/images/chuchu.png';
import jilo from '../assets/images/jilo.png';
import pepinoCaipira from '../assets/images/pepinoCaipira.png'
import pepinoComum from '../assets/images/pepinoComum.png';
import pimentaoVerde from '../assets/images/pimentaoVerde.png';
import quiabo from '../assets/images/quiabo.png';
import repolhoVerde from '../assets/images/repolhoVerde.png';

function Produtos() {
  const produtos = [
    { nome: 'Abobrinha Brasileira', categoria: 'Legume', imagem: abobrinhaBrasileira, width: 120 },
    { nome: 'Abobrinha Italiana', categoria: 'Legume', imagem: abobrinhaItaliana, width: 70 },
    { nome: 'Berinjela', categoria: 'Legume', imagem: berinjela, width: 120 },
    { nome: 'Beterraba', categoria: 'Legume', imagem: beterraba, width: 100 },
    { nome: 'Pepino Comum', categoria: 'Legume', imagem: pepinoComum, width: 140 },
    { nome: 'Pimentão Verde', categoria: 'Legume', imagem: pimentaoVerde, width: 130 },
    { nome: 'Cenoura', categoria: 'Legume', imagem: cenoura, width: 130 },
    { nome: 'Chuchu', categoria: 'Legume', imagem: chuchu, width: 120 },
    { nome: 'Jiló', categoria: 'Legume', imagem: jilo, width: 140 },
    { nome: 'Pepino Caipira', categoria: 'Legume', imagem: pepinoCaipira, width: 50 },
    { nome: 'Quiabo', categoria: 'Legume', imagem: quiabo, width: 150 },
    { nome: 'Repolho Verde', categoria: 'Verdura', imagem: repolhoVerde, width: 125 },
  ];

  return (
    <div id="produtos" className='sm:mt-0 mt-10 py-16 relative'>
      <h2 className='text-[3rem] font-bold w-full text-center sm:text-[2rem]'>Conheça nossos <span className='text-verde-escuro2'>produtos</span></h2>
      <Swiper
        modules={[Grid, Navigation]}
        grid={{
          rows: 2,
          fill: 'row'
        }}
        navigation={{
          prevEl: '.swiper-button-prev-custom',
          nextEl: '.swiper-button-next-custom',
        }}
        spaceBetween={20}
        slidesPerView={4}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          500: {
            slidesPerView: 4,
          }
        }}
        centeredSlides={false}
        className='mt-20'
      >
        {produtos.map((produto, index) => (
          <SwiperSlide key={produto.nome}>
            <div className="produto-card h-52 flex flex-col items-center justify-center">
              <div className='rounded-[20px] border-[3px] border-black/10 shadow-2xl h-32 w-36 flex items-center justify-center'>
                <img src={produto.imagem} alt={produto.nome} width={produto.width} />
              </div>
              <h3 className='font-bold text-lg mt-3'>{produto.nome}</h3> 
              <p className='font-bold text-sm text-black/75 -mt-2' >{produto.categoria}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <button className="swiper-button-prev-custom flex items-center justify-center absolute top-1/2 translate-y-1/2 -left-4 z-10 bg-[#E8E8E8] rounded-full shadow-md hover:brightness-[.80]">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="18" 
          height="36" 
          viewBox="0 0 18 36" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="3" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="m13 27-9-9 9-9"/>
        </svg>
      </button>
      
      <button className="swiper-button-next-custom flex items-center justify-center absolute top-1/2 translate-y-1/2 -right-4 z-10 bg-[#E8E8E8] rounded-full shadow-md hover:brightness-[.80]">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="18" 
          height="36" 
          viewBox="0 0 18 36" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="3" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="m4 27 9-9-9-9"/>
        </svg>
      </button>
    </div>
  );
}

export default Produtos;